import { useEffect, useMemo, useRef } from 'react';

import { type CodeCanvasBlock } from '@lp-lib/game';

import { type CodeCanvasBlockControlAPI } from './CodeCanvasPlayground';
import { CodeCanvasUtils } from './utils';

export function CodePlayer(props: {
  block: CodeCanvasBlock;
  ctrl: CodeCanvasBlockControlAPI;
  onLoad: () => void;
}) {
  const { block, ctrl, onLoad } = props;

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!iframeRef.current) return;

    const lvoCtrl = ctrl.getLVOCtrl();
    if (!lvoCtrl) return;

    const off = lvoCtrl.vm.on('marker-reached', (markerId) => {
      console.log('marker-reached', markerId);
      iframeRef.current?.contentWindow?.postMessage(
        {
          type: 'trigger',
          name: markerId,
        },
        '*'
      );
    });

    return () => {
      off();
    };
  }, [ctrl]);

  const src = useMemo(() => {
    return CodeCanvasUtils.GetCodeS3Url(block.id, block.fields.codeTimestamp);
  }, [block.id, block.fields.codeTimestamp]);

  if (!src) return null;

  return (
    <iframe
      title='code-canvas'
      className='w-full h-full'
      src={src}
      onLoad={onLoad}
    />
  );
}
